import React, {useEffect, useState} from 'react';
import './ChangeEmail.scss';
import IcResetLinkSent from '@icons/ic-change-email-success.svg';

import {useQueryParam, StringParam} from "use-query-params";
import {confirmChangeEmail} from '@repository/UserRepository';
import { navigate } from 'gatsby';

function ChangeEmail() {
  const [token, setToken] = useQueryParam("token", StringParam);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    confirmChangeEmail(token)
      .then(() => {
        
      })
      .catch((err) => {
        setIsError(true);
        console.log(err.response);
      });
  }, []);

  return (
    <div
      id="change-email-success"
      className="container py-5 d-flex flex-column align-items-center justify-content-center">
      <img src={IcResetLinkSent} />
      <div className="dosis-title-1 text-philadelphia-blue mb-2">
        Change Email {isError ? "Invalid" : "Success"}
      </div>
      <div className={`asap-body mb-3 ${isError && ' text-danger'}`}>
        {
          isError
            ? "Your URL is invalid, please request a new one."
            : "Your account e-mail address has been changed successfully."
        }
      </div>
      <button className="button-md button-primary" onClick={() => navigate('/login')}>
        Login Now
      </button>
    </div>
  );
}

export default ChangeEmail;
